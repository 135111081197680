<template>
  <div v-if="metadata != null">
    <cargill-crud-meta-view
      :service="crudService"
      :metadata="metadata"
      translatePrefix="application.pages"
      :reloadData="reloadData"
      @onReloadData="reloadData = false"
    />
  </div>
</template>

<script>
import service from '../api/optimizationQueueService'
import optimizationService from '../api/optimizationService'
import moment from 'moment'
import FileDownload from 'js-file-download'

import {
  CargillCrudMetaView,
  scenarioCargillService,
  useScenarioOpen,
  useUserSettings
} from '@cargill/shared'
import { CrudAction } from '@brain/core'
import StatusCellRenderer from '../components/status-cell-renderer/StatusCellRenderer'
import Vue from 'vue'
import { i18n } from '@cargill/shared'
const { updateScenarioOpen } = useScenarioOpen()
const { getUserSettings } = useUserSettings()
const fieldApplyFormat = [
  'demandAttendedIM',
  'demandAttendedEM',
  'percentageAttendance',
  'pd'
]

export default {
  components: { CargillCrudMetaView },
  data() {
    return {
      metadata: null,
      crudService: service,
      reloadData: false
    }
  },
  created() {
    this.$notification.on(
      'OptimizationUpdateNotification',
      this.optimizationUpdate
    )
    const getMeta = async () => {
      const meta = await service.getMeta()
      const removedPermissions = new Set([
        CrudAction.CREATE,
        CrudAction.UPDATE,
        CrudAction.DELETE,
        CrudAction.IMPORT
      ])
      meta.permissions = meta.permissions.filter(
        (p) => !removedPermissions.has(p)
      )

      var statusField = meta.fields.find((x) => x.id == 'status')
      statusField.cellRendererFramework = Vue.extend(StatusCellRenderer)
      statusField.width = 325
      meta.actions = [
        {
          title: this.$t('application.pages.optimizationQueueEntity.visualize'),
          className: 'fas fa-eye',
          enable: ({ node }) => {
            return optimizationService.isActive(node.data)
          },
          callback: async ({ node }) => {
            const scenario = await scenarioCargillService.getById(
              node.data.scenarioId
            )
            if (scenario.lastUpdatedDate)
              scenario.lastUpdatedDate = moment(
                scenario.lastUpdatedDate
              ).format(this.$t('application.dateFormat'))
            updateScenarioOpen(scenario)
            this.$router.push('/optimization')
          }
        },
        {
          title: this.$t(
            'application.pages.optimizationQueueEntity.stopOptimization'
          ),
          className: 'fas fa-times-hexagon',
          enable: ({ node }) => {
            const userSettings = getUserSettings()
            const hasPermissionToStop =
              userSettings?.id == node.data?.requestingUserId
            return (
              optimizationService.canRequestStop(node.data) &&
              hasPermissionToStop
            )
          },
          callback: ({ node }) => {
            optimizationService.stop(node.data.id)
          }
        },
        {
          title: this.$t(
            'application.pages.optimizationQueueEntity.exportLogs'
          ),
          className: 'fas fa-download',
          enable: ({ node }) => {
            return node.data.hasLogs
          },
          callback: async ({ node }) => {
            const response = await service.exportLogs(node.data.id)
            FileDownload(response.data, response.filename)
          }
        }
      ]
      return meta
    }
    getMeta().then((meta) => {
      this.metadata = meta
      this.includeFormat(this.metadata)
    })
  },
  methods: {
    optimizationUpdate() {
      this.reloadData = true
    },
    includeFormat(meta) {
      const fields = meta.fields.filter((field) =>
        fieldApplyFormat.includes(field.id)
      )
      fields.forEach((item) => {
        item.valueFormatter = this.formatNumberToThousands

        item.valueFormatterForm = this.formatNumberToThousands
      })
    },
    formatNumberToThousands(params) {
      if (params.value == null) {
        return ''
      }

      let numberValue = Number(params.value)

      if (isNaN(numberValue)) {
        return ''
      }
      return numberValue.toLocaleString(i18n.locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    }
  },
  async beforeDestroy() {
    this.$notification.off(
      'OptimizationUpdateNotification',
      this.optimizationUpdate
    )
  }
}
</script>

<style lang="scss" scoped>
.tasks-container {
  .label {
    background: #232834;
    color: #959db5;
    font-size: 0.7vw;
    font-weight: bold;
    text-align: left;
    padding: 12px 15px;
    text-transform: uppercase;
  }
  .table-container {
    margin-top: 10px;
    background: #232834;
    padding: 12px 15px;
  }
  .actions {
    text-align: right;
  }
}
</style>
