<template>
  <div class="cell">
    <div
      class="status"
      :style="'background-color:' + this.params.data.statusColor"
    ></div>
    <div class="value">{{ this.params.valueFormatted }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.cell {
  display: -webkit-box;
  align-items: center;
  gap: 10px;
  height: 100%;
  width: 100%;
}
.status {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: inline-block;
}
.value {
  text-transform: uppercase;
}
</style>
